<template>
  <div class="index-wrapper">
    <!-- :src="`https://client.taohaobang.com?from=mydj&phone=${phone}&timer=${timer}&token=${token}&url=${this.linkUrl}`" http://dianjin.taohaobang.com -->
    <iframe
      :src="`https://client.taohaobang.com/login?from=mydj&phone=${phone}&timer=${timer}&token=${token}&url=${this.linkUrl}`"
      width="100%"
      height="716px"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { md5 } from "@/util/crypto";

export default {
  computed: {
    ...mapGetters([
      "userInfo",
      "netBarId",
      "netBarName",
      "netBarVIP",
      "isLogin",
    ]),
    linkUrl: function () {
      if (this.$route.query && this.$route.query.url) {
        return this.$route.query.url.substr(10, this.$route.query.url.length);
      }
    },
  },
  data() {
    return {
      phone: "",
      token: "",
      timer: "",
    };
  },
  mounted() {
    if (this.isLogin && this.userInfo) {
      this.phone = this.userInfo.Phone;
      this.timer = new Date().getTime();
      let str = this.phone + "-THB-WEB-FOR-CLIENT-" + this.timer;
      this.token = md5(str);
    }
  },
};
</script>

<style lang="scss" scoped>
.index-wrapper {
  height: 716px;
  overflow: hidden;
  background: $white-col;
}
</style>